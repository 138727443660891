@import '../../assets/styles/00-abstracts/abstracts';@import '../../assets/styles/01-mixins/mixins';.arjo-o-b2c-placeholder {
  .error {
    color: $text-error;
    font-size: 16px;

    &.pageLevel {
      margin-top: 10px;
    }
  }

  .intro {
    display: none;
  }

  .entry {
    margin-top: 40px;
  }

  .entry-item {
    position: relative;
    display: flex;
    flex-direction: column;

    label {
      display: block;
      font-family: $font-santral;
      font-size: 18px;
      line-height: 23px;
      font-weight: $semi-bold;
      color: $lava;
    }

    input {
      margin-top: 5px;
      padding: 0 14px;
      display: block;
      width: 100%;
      height: 46px;
      border: 1px solid $sand-s;
      background-color: $white-p;

      &,
      &:-webkit-autofill::first-line,
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        font-family: $font-santral;
        font-weight: $semi-bold;
        font-size: 18px;
        color: $lava;
      }
    }

    .error {
      margin-top: 5px;
      order: 100;
    }

    + .entry-item {
      margin-top: 20px;
    }
  }

  .buttons {
    margin-top: 50px;

    button:not(.arjo-a-button):not([data-custom-style]) {
      min-height: 51px;
      border-radius: 30px;
      text-transform: uppercase;
      font-size: 18px;
      margin: 6px;
    }

    &.verify {
      order: 101;
    }
  }

  .create {
    margin-top: 20px;
    font-family: $font-santral;
    font-weight: $semi-bold;
    font-size: 18px;
    color: $lava;

    a {
      font-family: $font-santral;
      font-size: 18px;
      line-height: 23px;
      font-weight: $semi-bold;
      color: $arjo-blue-p;

      &:hover {
        text-decoration: none;
      }
    }
  }

  #forgotPassword {
    margin-top: 5px;
    display: block;
    font-family: $font-santral;
    font-size: 16px;
    line-height: 23px;
    font-weight: normal;
    color: $arjo-blue-p;
    order: 1000;

    &:hover {
      text-decoration: none;
    }
  }

  .password-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .password-toggle {
    position: relative;
    padding: 0 0 0 30px;
    border: 0;
    background-color: transparent;
    color: $arjo-blue-p;
    text-transform: none;
    cursor: pointer;

    svg {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    div {
      position: relative;
      font-family: $font-santral;
      font-weight: $semi-bold;
      font-size: 18px;
      line-height: 18px;
      vertical-align: middle;
      display: inline-block;

      @media (pointer: none), (pointer: coarse) {
        vertical-align: -webkit-baseline-middle;
      }

      @supports (-webkit-touch-callout: none) {
        vertical-align: -webkit-baseline-middle;
      }
    }

    &:hover {
      color: $black;
    }
  }

  #attributeList {
    ul {
      list-style-type: none;
      padding: 0;

      li {
        .attrEntry {
          position: relative;
          display: flex;
          flex-wrap: wrap;

          a.helpLink {
            display: none;
          }

          label {
            display: block;
            font-family: $font-santral;
            font-size: 18px;
            line-height: 23px;
            font-weight: $semi-bold;
            color: $lava;
          }

          button.password-toggle {
            margin-left: auto;
          }

          input:not([type='checkbox']) {
            margin-top: 5px;
            padding: 0 14px;
            display: block;
            width: 100%;
            height: 46px;
            border: 1px solid $sand-s;
            background-color: $white-p;

            &,
            &:-webkit-autofill::first-line,
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
              font-family: $font-santral;
              font-weight: $semi-bold;
              font-size: 18px;
              color: $lava;
            }
          }

          input[type='checkbox'] {
            height: 30px;
            width: 30px;
            margin-right: 20px;
            flex-shrink: 0;
            flex-grow: 0;
            appearance: initial;
            border: 1px solid $sand-s;
            background-color: $white-p;
            position: relative;

            &:checked {
              &:before,
              &:after {
                content: '';
                border-top: 1px solid $lava;
                width: 20px;
                position: absolute;
                top: 50%;
                left: 50%;
              }

              &:before {
                transform: translate(-50%, -50%) rotate(-45deg);
              }

              &:after {
                transform: translate(-50%, -50%) rotate(45deg);
              }
            }
          }

          select {
            height: 45px;
            display: block;
            background-color: $white-p;
            font-family: $font-santral;
            font-size: 20px;
            font-weight: $semi-bold;
            color: $lava;
            width: 100%;
            border: 1px solid $wave-ac;
            border-radius: 0;
            margin: 0;
            margin-top: 7px;
            padding: 0 15px;
          }

          div {
            :has(.verificationInfoText) {
              display: block;
              width: 100%;
              margin-bottom: 40px;
            }
          }

          #accepted_option {
            align-self: center;
          }

          .error {
            margin-top: 5px;
            width: 100%;
            order: 100;
          }
        }
      }

      li:not(:first-child) {
        margin-top: 20px;
      }
    }
  }
}
